<template>
  <div>
    <div
      class="forwarded-caption"
      v-if="activeChat.id == message.id"
    >
      <v-icon>mdi-share-outline</v-icon>
      Current Chat
    </div>
    <div
      class="forwarded-caption"
      v-if="message.direction == 'MODERATOR_EXPERT'"
    >
      <v-icon>mdi-share-outline</v-icon>
      You Forwarded to Expert
    </div>
    <div
      class="forwarded-caption"
      v-if="message.direction == 'EXPERT_MODERATOR'"
    >
      <v-icon>mdi-share-outline</v-icon>
      Expert send an answer
    </div>

    <div class="shadow-message" :class="getMessageClass(message.direction)" v-if="getMessageClass(message.direction)=='float-right'">
      {{message.replyToMsg}}
    </div>
    <div style="clear:both">

    </div>
   
    <div class="message-text" :class="getMessageClass(message.direction)">
       
      <!-- TODO check empty file name -->
      
      <div class="msg-media" v-if="message.fileNames && message.fileNames.length > 0">
        <a
          :href="file_url+'/' + img"
          target="_blank"
          v-for="(img, index) in message.fileNames"
          :key="index*Math.random()"
          class="mr-2"
        >
          <img height="100" width="100" :src="file_url+'/'+ img"
            alt="asdasd"
            v-if="
              checkFileType(img) == 'jpeg' ||
              checkFileType(img) == 'jpg' ||
              checkFileType(img) == 'png'
            "
            name="img"
            style="display: block; margin-bottom: 10px"
          />
        </a>
       </div>

      {{ message.message }}

      <div class="message-date">
        {{ formatDate(message.dateTime, "MM DD :A ") }}
      </div>
    </div>

    <div style="clear: both"></div>

   
  </div>
</template>


<script>
export default {
  data(){
    return{
      file_url: process.env.VUE_APP_FILE_URL
    }
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    currentUser: {
      required: true,
    },
  },

  methods: {
    getDirectionClass(val) {
      let currentDirection = val.split("_")[0];
      if (currentDirection == this.currentUser) {
        return "float-right";
      } else {
        return "float-left";
      }
    },

    getMessageClass(val) {
      if (val == "MODERATOR_CUSTOMER") {
        // MODERATER REPLING TO CUSTOMER
        return "float-right";
      } else if (val == "MODERATOR_EXPERT") {
        // FORWARDED MESSAGE
        return "float-left moderator";
      } else if (val == "EXPERT_MODERATOR") {
        // EXPERT's REPLY
        return "float-left";
      } else if (val == "EXPERT_EXPERT") {
        // EXPERT ASKING ANOTHER DEPARTMENT
        return "float-right";
      } else {
        // CUSTOMER_MODERATOR
        return "float-left customer";
      }
    },
    checkFileType(file) {
      return file.split(".").pop();
    },
  },
  computed:{
    taskDetails(){
      return this.$store.getters.taskDetails;
    },
    activeChat(){
      return this.$store.getters.activeChat;
    }
  }
};
</script>


<style scoped>
.moderator {
  background: #0084ff;
  color: #fff;
}

.moderator .message-date {
  color: #fff;
}

.customer {
  background: #67b868;
  color: #fff;
}

.customer .message-date {
  color: #fff;
}

.forwarded-caption {
  font-size: 12px;
  color: #8a8d91;
}

.msg-media {
  display: flex;
  flex-wrap: wrap;
}

.message-text{
  position: relative;
}

.shadow-message{
  border-radius: 15px;
  background: #F6F9FA;
  padding: 15px;
  margin-bottom: -10px ;
  }


</style>