<template>
  <v-container fluid>
    <div class="enlarge-image" v-if="showEnlarge">
      <div class="close-btn" @click="closeEnlarge">
        <v-icon color="white">mdi-close</v-icon>
      </div>
      <img
        src="https://message.hyphen.noodle-code.com/file/b464a18544182d731091445777f12152c71acfd83.jpg"
        alt=""
      />
    </div>
    <div class="py-4">
      <!-- <v-btn @click="joinConversation">Join</v-btn>
    <v-btn @click="dummySend">Send</v-btn> -->

      <v-progress-circular
        indeterminate
        color="primary"
        v-if="loading"
      ></v-progress-circular>

      <div v-else>
        <v-row v-if="messages.length > 0">
          <v-col md="9" class="mr-0 pr-0 p-0">
            <div style="position: relative">
              <div class="chat">
                <div class="chat-box" v-chat-scroll>
                  <div
                    class="message-item"
                    v-for="(message, index) in messages"
                    :class="'message-' + index"
                    :key="index"
                  >
                    <MessageBubble
                      :message="message"
                      :currentUser="'MODERATOR'"
                    />
                  </div>
                </div>
                <div class="chat-actions-wrapper">
                  <div class="preview-image-wrapper">
                    <div v-for="(preview, index) in previewImage" :key="index">
                      <v-badge color="#fff" overlap>
                        <img :src="preview" alt="" class="preview-image" />
                        <template v-slot:badge>
                          <v-icon color="red" @click="removeFile(index)"
                            >mdi-trash-can-outline</v-icon
                          >
                        </template>
                      </v-badge>
                    </div>
                  </div>

                  <div class="active-chat-wrapper">
                    <div class="active-chat-label">Active Chat</div>

                    <div>
                      {{ activeChat.message }}
                    </div>
                  </div>

                  <div class="chat-input-wrapper">
                    <v-text-field
                      label="Write Your Response"
                      type="text"
                      flat
                      solo
                      hide-details="auto"
                      required
                      v-model="typeMessage"
                    ></v-text-field>

                    <div class="chat-actions">
                      <div class="files-attachment">
                        <input
                          id="fileUpload"
                          type="file"
                          hidden
                          ref="fileUpload"
                          multiple="true"
                          @input="pickFile"
                        />
                        <v-icon large @click="openFileUpload">
                          mdi-attachment
                        </v-icon>
                      </div>

                      <div>
                        <v-dialog
                          transition="dialog-bottom-transition"
                          max-width="600"
                          v-model="dialog"
                          v-if="options.enableExpertHelp"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="blue" v-bind="attrs" v-on="on" small
                              >Get Help From Expert</v-btn
                            >
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="blue" dark
                                >Choose the Department you want to forward
                                to</v-toolbar
                              >
                              <v-card-text>
                                <v-select
                                  :items="departments"
                                  item-text="name"
                                  item-value="id"
                                  filled
                                  label="Departments"
                                  v-model="chosenDepartment"
                                ></v-select>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn text @click="getHelpFromExpert"
                                  >Submit</v-btn
                                >
                                <v-btn text @click="dialog.value = false"
                                  >Cancel</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>

                        <v-btn small color="blue" v-if="options.enableRecredit"
                        @click="sendMessage('recredit')"
                          >Send : Recredit</v-btn
                        >
                        <v-btn
                          small
                          color="primary"
                          class="mr-0"
                          @click="sendMessage"
                          >Send
                          <v-icon class="pl-2" dark small
                            >mdi-send</v-icon
                          ></v-btn
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <v-col md="3" class="ml-0 pl-0 p-0">
            <div class="profile">
              <h3 class="text-center mt-3">Conversation Details</h3>

              <v-container fluid>
                <div
                  class="d-flex flex-column align-start justify-content-start"
                >
                  <div class="d-flex">
                    <div class="item-title">Customer Name</div>
                    <div class="item-content">
                      {{ taskDetails.customerProfile.name }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="item-title">Environment Type</div>
                    <div class="item-content">
                      {{ taskDetails.conversationDetail.environmentType }}
                    </div>
                  </div>

                  <div class="d-flex">
                    <div class="item-title">Area</div>
                    <div class="item-content">
                      {{ taskDetails.conversationDetail.area }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="item-title">Location</div>
                    <div class="item-content">
                      {{ taskDetails.conversationDetail.location }}
                    </div>
                  </div>
                </div>
              </v-container>

              <h3 class="text-center mt-3">Involvement</h3>
              <v-container fluid>
                <div
                  class="d-flex flex-column align-start justify-content-start"
                >
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Name</th>

                          <th class="text-left">Percent</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          tr
                          v-for="inv in taskDetails.conversationDetail
                            .involvements"
                          :key="inv.id"
                        >
                          <td>{{ inv.moderator.name }}</td>

                          <td>{{ inv.percent }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-container>
            </div>
          </v-col>
        </v-row>
        <div class="no-message" v-else>
          <v-card outlined class="">
            <v-card-text class="text-center">
              <v-icon large>mdi-android-messages</v-icon>
              <h4 class="display-2 mt-3">You Dont Have Any Message Yet</h4>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </v-container>
</template>


<script>
import CustomerAPi from "./../../../../api/message";
import MessageBubble from "./MessageBubble.vue";

export default {
  props: {
    departments: {},
    options: {
      type: Object,
    },
  },

  sockets: {
    connect() {
      this.joinConversation();
    },
  },
  components: {
    MessageBubble,
  },
  data() {
    return {
      dialog: false,
      loading: true,
      chosenDepartment: 1,
      typeMessage: "",
      messages: [],
      previewImage: [],
      photos: [],
      currentUploadedFiles: [],
      ownDetail: {},
      showEnlarge: false,
      currentEnlargeImage: null,
      };
  },
  methods: {
    connectSocket() {
      let accessToken = this.$store.getters.loggedInUser.accessCode.accessToken;
      this.$socket.client.io.opts.extraHeaders.Authorization = accessToken;
      this.$socket.client.open();
      this.subscribeListeners();
    },
    joinConversation() {
      this.$socket.client.emit("joinConversation", {
        conversationId: this.taskDetails.conversationId,
      });
    },
    subscribeListeners() {
      let vm = this;
      this.$socket.client.on("chat-listener", function (e) {
        vm.messages.push({
          message: e.message,
          dateTime: e.dateTime,
          id: e.id,
          direction: e.direction,
          fileNames: e.fileNames,
          replyToMsg: e.replyToMsg
        });
      });

      // this pulls entire conversation first time
      this.$socket.client.on("conversation-listener", function (e) {
        console.log("Entire thing",e);
        vm.messages = e.data.chatMessages;
        vm.loading = false;
      });

      // this returns current active chat
      this.$socket.client.on("active-task-listener", function (e) {
        if (e.isSuccess) {
          console.log("TEST",e);
          let activeChat = {
            id: e.data.id,
            message: e.data.message,
          };
          vm.$store.commit('setTaskDetails',e.data);
          vm.$store.commit("setCurrentActiveChat", activeChat);
        } else {
          vm.$router.push({ path: "/" });
        }
      });
    },

    getHelpFromExpert() {
      let data = {
        conversationId: this.taskDetails.conversationId,
        messageActivityId: this.taskDetails.messageActivityId,
        departmentId: this.chosenDepartment,
      };
      this.$socket.client.emit("moderatorForward", data);
      this.dialog = false;
    },

    prepareFileUploadMessage() {
      let promises = [];
      this.photos.forEach((item) => {
        let formData = new FormData();
        formData.append("file", item);
        promises.push(this.$MessageApi.fileUpload(formData));
      });
      Promise.all(promises).then((data) => {
        data.forEach((d) => {
          this.currentUploadedFiles.push(d.data.data);
        });

        let details = {
          message: this.typeMessage,
          conversationId: this.taskDetails.conversationId,
          messageActivityId: this.taskDetails.messageActivityId,
          uploadFiles: this.currentUploadedFiles,
        };
        console.log("Sending message activity", details)

        this.$socket.client.emit("moderatorReply", details);
        this.typeMessage = "";
        this.currentUploadedFiles = [];
        this.previewImage = [];
      });
    },

    sendMessage(recredit) {
      if (this.photos) {
        if (this.photos.length > 0) {
          this.prepareFileUploadMessage();
        } else {
          let details = {
            message: this.typeMessage,
            conversationId: this.taskDetails.conversationId,
            messageActivityId: this.taskDetails.messageActivityId,
            recredit: recredit && recredit
          };
          this.$socket.client.emit("moderatorReply", details);
          this.typeMessage = "";
        }
      }
    },

    scrollToElement() {
      let index = this.items.length;
      let className = `message-${index}`;
      const el = this.$el.getElementsByClassName(className)[0];
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    openFileUpload() {
      console.log(this.$refs.fileUpload.click());
    },
    pickFile() {
      this.photos = []
      let input = this.$refs.fileUpload;
      let file = input.files;
      console.log(file);
      Array.from(file).forEach(item=>{
          let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage.push(e.target.result);
        };
        reader.readAsDataURL(item);
        this.photos.push(item);
      })
    },
    selectImage() {},
    removeFile(index) {
      this.previewImage.splice(index,1)
    },

    // OwnDetails
    getOwnDetails() {
      CustomerAPi.getOwnDetail()
        .then((data) => {
          this.ownDetail = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    showImage(image) {
      this.currentEnlargeImage = image;
      this.showEnlarge = true;
    },

    closeEnlarge() {
      this.showEnlarge = false;
    },
  },
  beforeDestroy() {
    this.$socket.client.off("active-task-listener");
    this.$socket.client.off("conversation-listener");
    this.$socket.client.off("chat-listener");
    this.$socket.client.close();
  },

  created() {},
  computed: {
    taskDetails() {
      return this.$store.getters.taskDetails;
    },
    activeChat() {
      return this.$store.getters.activeChat;
    },
  },

  mounted() {
    this.getOwnDetails();
    setTimeout(() => {
      this.connectSocket();
    }, 10);
  },
};
</script>

<style>
.chat-box {
  background: #fff;
}

.chat-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.chat-box::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.chat-box::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.chat {
  height: 650px;
}

.chat-box {
  height: 400px;
  overflow-y: scroll;
  padding: 0 15px;
}

.preview-image-wrapper {
  display: flex;
}

.chat-actions-wrapper {
  /* position: absolute; */
  /* height: 40%; */
  overflow: hidden;
  bottom: 0;
  width: 100%;
  /* margin-top: 10px; */
  min-height: 60px;
  padding: 10px 15px;
  background: #fff;
}

.active-chat-wrapper {
  padding: 20px 20px;
  background: #e5f7fc;
  border-radius: 10px;
  margin-block: 5px;
}

.active-chat-label {
  font-weight: 600;
}

.chat-actions {
  margin-top: 15px;
  padding-top: 5px;
  padding-bottom: 6px;
  display: flex;
  justify-content: space-between;
}

.chat-input-wrapper {
  border: 1px solid #989eb3;
  border-radius: 10px;
  padding: 10px 20px;
}

.profile {
  background: #fff;
  
  padding-top: 20px;
  border-left: 1px solid #f5f5f5;
}

.profile .profile-img {
  height: 180px;
  width: 180px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
}

.details {
  min-height: 550px;
  padding: 20px 20px;
  border: 1px solid #ccc;
  border-left: 0;
}

.message-item {
  margin-top: 15px;
}

.message-text {
  font-size: 14px;
  max-width: 300px;
  background: #e8f5e9;
  color: #222;
  padding: 15px;
  display: block;
  border-radius: 15px;
}

.message-date {
  font-size: 10px;
  text-align: right;
  color: #aaa;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.selected-chat {
  background: #f5f5f5;
}

.preview-image {
  height: 70px;
  padding: 10px 0;
}

.message-item:last-child {
  margin-bottom: 100px;
}

.no-message {
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-title {
  min-width: 60px;
  text-align: left;
  color: rgb(158, 157, 157);
  font-weight: 600;
}

.item-content {
  padding-left: 20px;
  font-weight: 400;
}

/* enlarge image */
.enlarge-image {
  height: 80%;
  width: 80%;
  margin: 0 auto;
  background: #333;
  color: black;
  padding: 10px;
  position: absolute;
  top: 5%;
  left: 10%;
  z-index: 1000;
  display: flex;
  justify-content: center;

  /* CSS 3 */
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}

.close-btn {
  position: absolute;
  background: red;
  padding: 16px;
  border-radius: 50%;
  top: 50px;
  right: 50px;
}
</style>