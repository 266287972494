<template>
 <Chat :departments="departments" :options="options"/>
</template>


<script>
import CustomerAPi from "../../api/message";
import MessageBubble from "./component/message/MessageBubble.vue";
import Chat from "./component/message/Chat.vue";


export default {
 
  components:{
    MessageBubble,
    Chat
  },
  data() {
    return {
      
      chosenDepartment: 1,
      departments: [],
      options:{
        enableExpertHelp:true,
        enableNextChatFree:true,
        enableRecredit:true
      },
     
      ownDetail: {},
    };
  },
  methods: {


    getDepartments(){
      CustomerAPi.getDepartment().then(data=>{
        this.departments = data.data.data;
      }).catch(error=>{
        console.log(error);
      })
    },

    
   

     // OwnDetails
    getOwnDetails() {
      CustomerAPi.getOwnDetail()
        .then((data) => {
          this.ownDetail = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  created() {},

  mounted() {
    this.getOwnDetails();
    this.getDepartments();  
  },
};
</script>

<style>


</style>